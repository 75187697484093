import React from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';
import {
  MediaManagementActionCreators,
  RESOURCE_ALBUM,
  RESOURCE_PICTURES,
  resolvePictureTypeByName,
} from '../../../stores/MediaManagement/MediaManagement';
import AbstractComponent from '../../AbstractComponent';
import { SessionStore } from '../../../stores/Session/Session';

class AbstractAlbumView extends AbstractComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: props.isLoading,
      album: props.album,
      selectedPictures: {},
    };

    this.addPicturesToAlbum = this.addPicturesToAlbum.bind(this);
    this.deletePicture = this.deletePicture.bind(this);
    this.selectPicture = this.selectPicture.bind(this);
    this.isPictureSelected = this.isPictureSelected.bind(this);
    this.movePictureToPos = this.movePictureToPos.bind(this);
    this.movePictureToPos0 = this.movePictureToPos0.bind(this);
    this.getCurrentPath = this.getCurrentPath.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    //console.log(this.__proto__.constructor.name, 'componentWillReceiveProps', nextProps);
    this.setState({
      isLoading: nextProps.isLoading,
      album: nextProps.album,
    });
  }

  // picture functions
  addPicturesToAlbum(pictures) {
    for (let picture of pictures) {
      // we want to add
      picture.position = null;
      picture.pictureType = resolvePictureTypeByName(picture.pictureType);
    }

    this.setState({ isLoading: true }, () => {
      MediaManagementActionCreators.createResource(
        RESOURCE_PICTURES,
        {
          routeParams: {
            albumId: this.props.album.id,
          },
          data: pictures,
        },
        // onSuccess
        (response) => {
          this.setState({
            isLoading: false,
            album: response.album,
          });
        },
        // onError
        (response) => {
          this.setState({
            isLoading: false,
          });
        }
      );
    });
  }

  addPicturesToAlbum_GRAPHQL = (pictures) => {
    this.props
      .movePicturesToAlbum({
        variables: {
          sourceAlbumId: pictures[0].albumId, // all the pictures are in the same album
          targetAlbumId: this.props.album.id, // the album where we clicked the upload button
          pictures: pictures.map((picture) => ({ id: picture.pictureId })), // the api expect { id } of each pic
        },
      })
      .then((_) => this.props.refetchAlbum())
      .catch((err) => console.error(err));
  };

  static toLegacyPictureType(pictureType) {
    switch (pictureType) {
      case 'lustagenten':
        return 'communityProfile';
      case 'flirtlife':
        return 'flirtlifeProfile';
    }
  }

  movePictureToPool_GRAPHQL = ({ sourceAlbumId, pictures }) => {
    this.props
      .movePicturesToAlbum({
        variables: {
          sourceAlbumId,
          targetAlbumId: SessionStore.get().picturePoolUmaId,
          pictures,
        },
      })
      .then((_) => this.props.refetchAlbum())
      .catch((err) => console.error(err));
  };

  deletePicture(picture, callback) {
    if (!picture.notPermanentlyDelete) {
      this.setState({ isLoading: true }, () => {
        MediaManagementActionCreators.deletePictureFromAlbum(picture, callback);
      });
    }
  }

  deletePicture_GRAPHQL = ({ albumId, pictureId }) => {
    this.props
      .deletePicture({ variables: { albumId, pictureId } })
      .then((_) => this.props.refetchAlbum())
      .catch((err) => console.error(err));
  };

  selectPicture(picture) {
    if (this.props.filter(picture) === false) return;

    if (this.props.allowSelection && this.props.allowMultipleSelection) {
      /** Single & Multiple selection is allow. */
      this.setState(
        {
          selectedPictures: {
            ...this.state.selectedPictures,
            ['umpId_' + picture.pictureId]:
              picture.status !== 'wildcard' && this.isPictureSelected(picture) ? null : picture,
          },
        },
        () =>
          this.props.onPictureSelect(
            values(this.state.selectedPictures).filter((pic) => (pic ? pic : null))
          )
      );
    } else if (this.props.allowSelection) {
      /** Only single selection is allow. */
      const nextSelectedPictures = {};
      for (let key in this.state.selectedPictures) {
        nextSelectedPictures[key] = null;
      }
      nextSelectedPictures['umpId_' + picture.pictureId] =
        picture.status !== 'wildcard' ? picture : null;

      this.setState({ selectedPictures: nextSelectedPictures }, () => {
        this.props.onPictureSelect(
          values(this.state.selectedPictures).filter((pic) => (pic ? pic : null))
        );
      });
    } else {
      /** No selection is allow */
      this.props.onPictureClick(picture);
    }
  }

  isPictureSelected(picture) {
    return (
      !!picture &&
      this.state.selectedPictures.hasOwnProperty('umpId_' + picture.pictureId) &&
      !!this.state.selectedPictures['umpId_' + picture.pictureId]
    );
  }

  movePictureToPos(picture, newPicturePosition) {
    this.setState({ isLoading: true }, () => {
      MediaManagementActionCreators.movePictureToPosition(
        picture.albumId,
        picture.pictureId,
        newPicturePosition,
        // onSuccess
        (album) => {
          this.setState({
            isLoading: false,
            album: album,
          });
        }
      );
    });
  }

  movePictureToPos0(picture) {
    this.movePictureToPos(picture, 0);
  }

  onUploadComplete() {
    MediaManagementActionCreators.readResource(this.resource, this.params);
  }

  getCurrentPath() {
    const pathname = this.props.match.pathname || this.props.match.url;
    return pathname.substring(0, pathname.lastIndexOf('/'));
  }
}

AbstractAlbumView.debug = true;

AbstractAlbumView.propTypes = {
  album: PropTypes.shape({
    id: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  allowSelect: PropTypes.bool,
  allowDeletePicture: PropTypes.bool,
  allowAddPicture: PropTypes.bool,
  allowMovePictureToPos0: PropTypes.bool,
  allowMovePictureToPool: PropTypes.bool,
  allowDetails: PropTypes.bool,
  allowSetAsPreview: PropTypes.bool,
  showStatusInChecking: PropTypes.bool,
  showUploadError: PropTypes.bool,
  minPictures: PropTypes.number,
  onPictureClick: PropTypes.func,
  onPictureSelect: PropTypes.func,
  onPictureUnselect: PropTypes.func,
  onPictureDelete: PropTypes.func,
  onSetAsPreview16: PropTypes.func,
  onSetAsPreview18: PropTypes.func,
  onPictureMoveToPos0: PropTypes.func,
  onMoveToPool: PropTypes.func,
};

AbstractAlbumView.defaultProps = {
  album: {},
  isLoading: false,
  allowSelect: false,
  allowDeletePicture: false,
  allowAddPicture: false,
  allowMovePictureToPos0: false,
  allowMovePictureToPool: false,
  allowDetails: false,
  allowSetAsPreview: false,
  showStatusInChecking: false,
  showUploadError: false,
  minPictures: 0,
  onPictureClick: () => {},
  onPictureSelect: () => {},
  onPictureUnselect: () => {},
  onPictureDelete: () => {},
  onSetAsPreview16: () => {},
  onSetAsPreview18: () => {},
  onPictureMoveToPos0: () => {},
  onMoveToPool: () => {},
};

AbstractAlbumView.contextTypes = {
  router: PropTypes.object,
};

export default AbstractAlbumView;
