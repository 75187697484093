import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import Button from '../Button/Button';
import { Spinner } from '../../components';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { BLACK_2, BLUE_HOVER, BREAKPOINT_PHONE_CONDITION } from '../../camtool-styles';
import { FileStorageSettings } from '../../graphql/VXModels/types';
import {
  AttachmentInputOrFile,
  fileToInputAttachment,
  fileToUploadedInputAttachment,
} from '../../util/FileUtils';
import Preview from './Preview';
import { Box } from '@material-ui/core';
import { Field, FieldProps, Formik } from 'formik';
import { _ } from '../../util/translate';
import * as Yup from 'yup';
import UploadPicker from './UploadPicker';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import { NewInputField } from '../index';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: '8px 10px',
    fontSize: '16px',
  },
  noBorder: {
    border: 'none',
  },
  icon: {
    '> svg': {
      fill: BLUE_HOVER,
    },
  },
}));

const ContainerRow = styled.div`
  word-break: break-all;
  color: #b0b0b0;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  width: 100%;
`;

const InputRow = styled(ContainerRow)`
  flex: 0 0 auto; // Necessary for macOS Safari (T19318)
  height: auto;
`;

const StyledButton = styled(Button)`
  @media ${BREAKPOINT_PHONE_CONDITION} {
    padding: 0 8px 0 8px;
  }
  background: transparent;
  cursor: pointer;
  // > svg {
  //   fill: ${BLACK_2};
  // }

  &:hover {
    background: transparent;

    > svg {
      fill: ${BLUE_HOVER};
    }
  }
`;
styled(UploadPicker)`
  @media ${BREAKPOINT_PHONE_CONDITION} {
    font-size: 16px;
    padding: 0 12px 0 4px;
  }
`;
const EmojiPickerContainer = styled.div`
  position: absolute;
  bottom: 70px;
  right: 60px;
  z-index: 23;

  .emoji-mart-preview {
    display: none;
  }

  @media ${BREAKPOINT_PHONE_CONDITION} {
    right: 16px;
  }
`;

const MessageField = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;
  padding: 5px;
  width: 100%;
  @media ${BREAKPOINT_PHONE_CONDITION} {
    padding: 10px 10px;
  }

  .emoji-mart-category,
  .emoji-mart-scroll,
  section.emoji-mart {
    flex-wrap: wrap;
  }

  .emoji-mart-bar {
    width: 100%;
  }
`;

const initialValues = { text: '', file: null, price: '0' };
const validationSchema = () =>
  Yup.object().shape({
    text: Yup.string().when('file', {
      is: (file) => file === null,
      // prevents whitespace only message
      then: Yup.string().trim().required(),
      otherwise: Yup.string(),
    }),
    file: Yup.object().nullable(),
    // because of radio buttons numbers are strings -> convert to number for min max validation
    price: Yup.string().test(
      'is-in-price-range',
      _('telegram:chat.preview.priceError'),
      (string) => Number(string) === 0 || (Number(string) >= 3 && Number(string) <= 150)
    ),
  });

interface InlineEditorProps {
  emojiPicker: boolean;
  uploadPicker: boolean;
  onSubmit: ({
    text,
    fileUUID,
    price,
  }: {
    text: string;
    fileUUID?: string;
    price?: number;
  }) => void;
  sending: boolean;
  fileStorageSettings: FileStorageSettings;
}

const InlineEditor: FC<InlineEditorProps> = ({
  uploadPicker,
  onSubmit,
  sending,
  fileStorageSettings,
}) => {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const classes = useStyles();

  const [showEmojis, setShowEmojis] = useState(false);

  const [fileError, setFileError] = useState<string | null>(null);

  if (uploadPicker && !fileStorageSettings) {
    throw new Error('Option "uploadPicker" needs valid "fileStorageSettings" set');
  }

  const submit = (
    values: {
      text: string;
      file: AttachmentInputOrFile | null;
      price: string;
    },
    resetForm: (
      nextValues?:
        | {
            text: string;
            file: null;
            price: string;
          }
        | undefined
    ) => void
  ) => {
    // transform price back to number to make Mutation work
    const transformedValues = { ...values, price: Number(values.price) || 0 };
    const { text, file, price } = transformedValues;
    const attachmentFile = file?.file;

    if (text || attachmentFile) {
      if (attachmentFile) {
        setUploading(true);

        fileToUploadedInputAttachment(fileStorageSettings, attachmentFile)
          .then((attachment) =>
            onSubmit({
              text,
              fileUUID: attachment.payload,
              price,
            })
          )
          .then(() => {
            setUploading(false);
          });
      } else {
        onSubmit({ text });
      }
    }

    setShowPreview(false);
    setIsSubmitted(true);
    // resetForm alone does not reset DraftJS state therefore resetting it manually here
    resetForm(initialValues);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        submit(values, resetForm);
      }}
    >
      {({ values, handleSubmit, setFieldValue, dirty, errors }) => {
        const hasErrors = Object.keys(errors).length > 0;

        return (
          <form css={{ width: '100%', flexWrap: 'wrap' }} onSubmit={handleSubmit}>
            {(sending || uploading) && <Spinner size={'xs'} />}

            {values.file && showPreview && (
              <Preview
                name="file"
                disablePrice={values.file === null}
                onClose={() => {
                  setShowPreview(false);
                  setFieldValue('file', null);
                }}
              />
            )}

            <InputRow>
              {uploadPicker && (
                <Box p={{ xs: '12px 0px', md: '12px' }}>
                  <UploadPicker
                    clearInputFieldAfterSubmit={isSubmitted}
                    css={{ position: 'relative', right: 0 }}
                    selectedFile={values?.file !== null}
                    selectFile={async (file) => {
                      // transform file into genericupload compatible format
                      if (!file) {
                        setFileError('No file selected');
                      }

                      if (file.size > 2 * 1024 * 1024 * 1024) {
                        setFileError('File size exceeds 2GB');
                      }
                      const attachmentFile = await fileToInputAttachment(file);
                      setFieldValue('file', attachmentFile);
                      setShowPreview(true);
                    }}
                    accept={'image/*, video/*, audio/*'}
                  />
                </Box>
              )}

              <MessageField>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                  <Field name="text">
                    {({ field, form: { setFieldValue } }: FieldProps) => {
                      const changeText = (value: string) => setFieldValue('text', value);
                      const addEmoji = (e) => {
                        const sym = e.unified.split('-');
                        const codesArray = [];
                        sym.forEach((el) => codesArray.push('0x' + el));
                        const emoji = String.fromCodePoint(...codesArray);
                        setFieldValue('text', field.value + emoji);
                      };
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                          <NewInputField
                            value={field.value}
                            multiline={true}
                            onChange={(e) => {
                              changeText(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              const keyCode = e.which || e.keyCode;
                              if (keyCode === 13 && !e.shiftKey) {
                                handleSubmit();
                              }
                            }}
                            type="text"
                            fullWidth={true}
                            placeholder={_('atom:TextEditor.text.writeMessage')}
                            InputProps={{
                              classes: { root: classes.root, notchedOutline: classes.noBorder },
                            }}
                          />
                          <StyledButton onClick={() => setShowEmojis(!showEmojis)}>
                            {showEmojis ? (
                              <InsertEmoticonIcon style={{ color: BLUE_HOVER }} />
                            ) : (
                              <InsertEmoticonIcon style={{ color: BLACK_2 }} />
                            )}
                          </StyledButton>
                          {showEmojis && (
                            <ClickAwayListener
                              onClickAway={(e) => {
                                e.preventDefault();
                                setShowEmojis(false);
                              }}
                            >
                              <EmojiPickerContainer>
                                <Picker
                                  color={BLUE_HOVER}
                                  onSelect={(e) => {
                                    addEmoji(e);
                                  }}
                                  showPreview={false}
                                />
                              </EmojiPickerContainer>
                            </ClickAwayListener>
                          )}
                        </div>
                      );
                    }}
                  </Field>
                </div>
              </MessageField>

              <Button
                type="submit"
                disabled={!dirty || hasErrors || sending}
                theme="none"
                css={[{ padding: '0 8px 0 4px' }, dirty ? { color: BLUE_HOVER } : {}]}
              >
                <span className="icon-send" />
              </Button>
            </InputRow>
          </form>
        );
      }}
    </Formik>
  );
};

export default InlineEditor;
