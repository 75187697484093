import { AbstractRestApiStoreClass, AbstractRestApiActionCreatorsClass } from '../AbstractRestApi';
import { SessionStore } from '../Session/Session';

// define resources
const PROFILE_LA = 'PROFILE_LA';
const PROFILE_COMMON_ABOUTYOU = 'PROFILE_COMMON_ABOUTYOU';

let config = {
  name: 'Profile',
  resources: {
    PROFILE_COMMON_ABOUTYOU: {
      uri: {
        GET: '/v1/camtool/user/{userId}/onlineprofile/common/aboutyou',
        PUT: '/v1/camtool/user/{userId}/onlineprofile/common/aboutyou',
      },
      defaultData: {
        modelBirthdate: '',
        isBirthdayDisabled: false,
        modelGender: '',
        isGenderDisabled: false,
        profileAboutMe: {
          texts: {},
        },
        profileCharacteristics: [],
        profileCity: '',
        profileCountry: '',
        profileGeohashCalculated: '',
        profileGeohashCustom: '',
        profileLanguages: [],
        textInputLanguages: [],
        profileOccupation: '',
        profileRelationshipState: '',
        profileSexuality: '',
        profileTags: [],
        profileZipCode: '',
        formValues: {
          modelGender: [],
          profileSexuality: [],
          profileLanguages: [],
          profileTags: [],
          profileCharacteristics: [],
          profileRelationshipState: [],
          profileCountry: [],
        },
      },
    },
    PROFILE_LA: {
      uri: {
        GET: '/v1/camtool/user/{userId}/onlineprofile/la',
        PUT: '/v1/camtool/user/{userId}/onlineprofile/la',
      },

      defaultData: {
        userId: 0,
        laSettingsProfileActive: SessionStore.get().profile.isLAProfileActive,
        laSettingsAutomessagingActive: false,
        laSettingsLiveDatesActive: false,
        laSettingsActionDatesActive: false,
        laPreferences: {
          texts: {},
        },
        laPerfectDate: {
          texts: {},
        },
        laSexualExperiences: {
          texts: {},
        },
        laNickname: '',
        laNicknameStatus: '',
        laWelcomeText1: {
          texts: {},
        },
        laWelcomeText2: {
          texts: {},
        },
        laWelcomeText3: {
          texts: {},
        },
        laMailAttachmentUmaId: 0,
        laWelcomeText1Picture: {},
        laWelcomeText2Picture: {},
        laWelcomeText3Picture: {},
        formValues: [],
      },
    },
  },
};

class ProfileStoreClass extends AbstractRestApiStoreClass {}
class ProfileActionCreatorsClass extends AbstractRestApiActionCreatorsClass {}

const ProfileStore = new ProfileStoreClass(config);
const ProfileActionCreators = new ProfileActionCreatorsClass(config);

export { ProfileStore, ProfileActionCreators, PROFILE_LA, PROFILE_COMMON_ABOUTYOU };
