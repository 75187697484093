import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SessionStore } from '../../../../stores/Session/Session';
import AbstractComponent from '../../../AbstractComponent';
import Tooltip from '../../../Tooltip/Tooltip';
import PopUpMenu from '../../../PopUpMenu/PopUpMenu';
import { withAppState } from '../../../../util/AppState';
import { ApiLang } from '../../../../graphql/VXModels/types';
import { _ } from '../../../../util/translate';

class AlbumItemStatus extends AbstractComponent {
  onClickMenu(event) {
    event.stopPropagation();
    this.props.onClickMenu(this.props.data);
  }

  onClickDetails(event) {
    // console.log('onClickDetails', event);
    event.stopPropagation();
    this.props.onClickDetails(this.props.data);
  }

  onClickDelete(event) {
    event.stopPropagation();
    this.props.onClickDelete(this.props.data);
  }

  onClickMoveToPool(event) {
    event.stopPropagation();
    this.props.onClickMoveToPool(this.props.data);
  }

  onClickMovePictureToPos0(event) {
    event.stopPropagation();
    this.props.onClickMovePictureToPos0(this.props.data);
  }

  onClickSetAsPreview16(event) {
    event.stopPropagation();
    this.props.onClickSetAsPreview16(this.props.data);
  }

  onClickSetAsPreview18(event) {
    event.stopPropagation();
    this.props.onClickSetAsPreview18(this.props.data);
  }

  onClickPreview(event) {
    event.stopPropagation();
    this.props.onClickPreview(this.props.data);
  }

  parseRejectedRease(rs) {
    if (typeof rs === 'string') return rs;

    const [{ lang }] = this.props.appState;

    return rs[lang];
  }

  render() {
    const {
      data,
      isMenuVisible,
      sequentialNumber,
      showDelete,
      showDetails,
      showInChecking,
      showMoveToPool,
      showMoveToPos0,
      showSetAsPreview,
    } = this.props;
    const firstLanguage = SessionStore.get().spokenLangs[0] || ApiLang.de;

    const isRejected = !!this.parseRejectedRease(data.rejectionReason);
    const isWildcard = data.status === 'wildcard';
    const description = data.descriptions ? data.descriptions[firstLanguage] : '';
    const hasMenu =
      !isWildcard && (showDetails || showSetAsPreview || showDelete || showMoveToPool);
    const isChecked = data.isChecked && !isRejected && !isWildcard;
    const isInChecking = !data.isChecked && !isRejected && showInChecking;
    const isPreviewPicture = isChecked && data.isPreviewPicture; // (data.isPreviewPic16 === true || data.isPreviewPic18 === true);
    const isSocial = isChecked && data.isSocial;
    const isTooSmall = !!data.isTooSmall && !isRejected;
    const isVisitXBrand = isChecked && data.isVisitXBrand;
    const menuStatus = isMenuVisible ? 'show' : '';
    const vxhpMediapool = data.pictureType === 'mediapool';

    let rejectReason = '';
    if (isTooSmall) {
      rejectReason = _('mediamanagement:picfolder.rejectReason.pictureTooSmall');
    }

    if (isRejected) {
      rejectReason = this.parseRejectedRease(this.props.data.rejectionReason);
    }

    return (
      <div className="mediamanagement__pic__album__content__item__status">
        {vxhpMediapool && (
          <Fragment>
            <div className="mediamanagement__pic__album__content__item__status__fsk">
              # {sequentialNumber}
            </div>
            <div className="mediamanagement__pic__album__content__item__picture__description">
              {description}
            </div>
          </Fragment>
        )}
        {/* isChecked: FSK, PreviewPic, VisitXBrand, Social */}
        {!vxhpMediapool && (
          <Fragment>
            {isChecked && (
              <div
                className={`icon-fsk-${data.fsk} mediamanagement__pic__album__content__item__status__fsk`}
                title={_('mediamanagement:picfolder.contentItemDetails.rating')}
              />
            )}
            {isPreviewPicture && (
              <div
                className="mediamanagement__pic__album__content__item__status__picture"
                title={_('mediamanagement:picfolder.contentItemDetails.isPreviewPicture')}
              />
            )}
            {isVisitXBrand && (
              <div
                className="mediamanagement__pic__album__content__item__status__iconproduct-vx"
                title={_('mediamanagement:picfolder.contentItemDetails.isVisitXBrand')}
              />
            )}
            {isSocial && (
              <div
                className="mediamanagement__pic__album__content__item__status__icon-thumbs-up"
                title={_('mediamanagement:picfolder.contentItemDetails.isSocial')}
              />
            )}
          </Fragment>
        )}

        {/* notChecked && notRejected: In Prüfung */}
        {isInChecking && (
          <div className="mediamanagement__pic__album__content__item__status__warning-sign">
            {_('mediamanagement:picfolder.contentItemStatus.inchecking')}
          </div>
        )}

        {/* isRejected: Foto abgelehnt */}
        {isRejected && (
          <div className="mediamanagement__pic__album__content__item__status__ban-circle">
            {_('mediamanagement:picfolder.contentItemStatus.pictureRejected')}
          </div>
        )}

        {/* isTooSmall: */}
        {isTooSmall && (
          <div className="mediamanagement__pic__album__content__item__status__warning-sign">
            {_('mediamanagement:picfolder.contentItemStatus.pictureTooSmall')}
          </div>
        )}

        {(isRejected || isTooSmall) && <Tooltip position="top" text={rejectReason} />}

        <div className="mediamanagement__pic__album__content__item__status__spacer" />
        {hasMenu && (
          <div
            className={`mediamanagement__pic__album__content__item__status__option-vertical popupmenu ${menuStatus}`}
            onClick={this.onClickMenu}
          >
            <PopUpMenu position="top">
              {showDetails && (
                <div className="icon-info-sign" onClick={this.onClickDetails}>
                  <span onClick={this.onClickDetails}>
                    {_('mediamanagement:picfolder.contentItemStatus.details')}
                  </span>
                </div>
              )}
              {showMoveToPos0 && data.isMovingToPos0Allowed && (
                <div className="icon-picture" onClick={this.onClickMovePictureToPos0}>
                  <span>{_('mediamanagement:picfolder.contentItemStatus.moveToPosition0')}</span>
                </div>
              )}
              {showSetAsPreview && data.isAllowedToSetAsPreview16 && (
                <div className="icon-picture" onClick={this.onClickSetAsPreview16}>
                  <span>{_('mediamanagement:picfolder.contentItemStatus.asPreviewPicture16')}</span>
                </div>
              )}
              {showSetAsPreview && data.isAllowedToSetAsPreview18 && (
                <div className="icon-picture" onClick={this.onClickSetAsPreview18}>
                  <span>{_('mediamanagement:picfolder.contentItemStatus.asPreviewPicture18')}</span>
                </div>
              )}
              {showDelete && (
                <div className="icon-bin" onClick={this.onClickDelete}>
                  <span>{_('mediamanagement:picfolder.contentItemStatus.permanentlyDelete')}</span>
                </div>
              )}
            </PopUpMenu>
          </div>
        )}
      </div>
    );
  }
}

AlbumItemStatus.propTypes = {
  data: PropTypes.shape({
    fsk: PropTypes.number,
    status: PropTypes.string,
    isChecked: PropTypes.bool,
    isRejected: PropTypes.bool,
    isPreviewPic16: PropTypes.bool,
    isPreviewPic18: PropTypes.bool,
    isVisitXBrand: PropTypes.bool,
    isSocial: PropTypes.bool,
    rejectionReason: PropTypes.string,
  }),
  isMenuVisible: PropTypes.bool,
  showDelete: PropTypes.bool,
  showMoveToPool: PropTypes.bool,
  showInChecking: PropTypes.bool,
  showDetails: PropTypes.bool,
  showSetAsPreview: PropTypes.bool,
  onClickMenu: PropTypes.func,
  onClickDetails: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickSetAsPreview16: PropTypes.func,
  onClickSetAsPreview18: PropTypes.func,
  onClickPreview: PropTypes.func,
  onClickMovePictureToPos0: PropTypes.func,
  onClickMoveToPool: PropTypes.func,
};

AlbumItemStatus.defaultProps = {
  data: {
    fsk: 18,
    status: '',
    isChecked: false,
    isRejected: false,
    isPreviewPic16: false,
    isPreviewPic18: false,
    isVisitXBrand: false,
    isSocial: false,
    rejectionReason: '',
  },
  isMenuVisible: false,
  showDelete: false,
  showMoveToPool: false,
  showInChecking: false,
  showDetails: false,
  showSetAsPreview: false,
  onClickMenu: () => {},
  onClickDetails: () => {},
  onClickDelete: () => {},
  onClickSetAsPreview16: () => {},
  onClickSetAsPreview18: () => {},
  onClickPreview: () => {},
  onClickMovePictureToPos0: () => {},
  onClickMoveToPool: () => {},
};

export default withAppState(AlbumItemStatus);
